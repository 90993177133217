import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, LoadingDots } from "../CommonComponents/Common";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Delete.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/Images/DownloadIcon.svg";
import FileUploadComponent from "./FileUploadComponent";
import AddIcon from "@mui/icons-material/Add";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { checkPromptAction } from "../ProjectSection/CampaignSection/Store/Campaign.action";
import NoticeAlert from "../Common/Alert/NoticeAlert";

const TabsSection = ({
  formState,
  setFormState,
  state,
  audienceData,
  setState,
}) => {
  const reducer: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    AgentReducer: { agents },
  } = reducer;
  const [msg, setMsg] = useState("");
  const [downloadexcelSpin, setdownloadexcelSpin] = useState(false);

  let inde = 0;

  function onInputChange(e, index) {
    const audienceList1 = [...audienceData];
    audienceList1[index][e.target.name] = e.target.value;
    setFormState({ ...formState, audienceData: audienceList1 });
  }

  const handlePhoneChange = (e, index) => {
    const phoneNumber = e.trim().replace(/\D/g, "");

    if (phoneNumber.length < 7) {
      setMsg("Please enter valid number.");
      return;
    }

    if (phoneNumber.length > 20) {
      setMsg("Phone number cannot be more than 20 numbers.");
      return;
    }

    const isDuplicate = audienceData?.some(
      (aud, i) => aud?.phone === phoneNumber && i !== index
    );
    if (isDuplicate) {
      setMsg("Duplicated Number Found.");
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
      return;
    }

    const audienceList1 = [...audienceData];
    audienceList1[index]["phone"] = e;
    setFormState({
      ...formState,
      audienceData: audienceList1,
    });

    setMsg("");
    setState((prev) => ({
      ...prev,
      buttonDisable: false,
    }));
  };

  function addAudienceForm() {
    if (formState.audienceData[inde].phone.length < 6) {
      setMsg(`Enter valid number`);
      setTimeout(() => {
        setMsg("");
      }, 5000);
    } else {
      setFormState({
        ...formState,
        audienceData: [
          ...formState.audienceData,
          { firstName: "", lastName: "", phone: "" },
        ],
      });
    }
  }

  function removeAudienceForm(index) {
    const audienceList1 = [...audienceData];
    if (audienceList1.length > 1) {
      audienceList1.splice(index, 1);
      setFormState({ ...formState, audienceData: audienceList1 });
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
      setMsg("")
    }
  }

  const downloadSampleExcel = async () => {
    let final: any = [];
    setdownloadexcelSpin(true);

    let defaultAgent = agents?.find((agen) => agen.default);
    if (!defaultAgent) {
      let obj1 = {
        FirstName: "",
        LastName: "",
        PhoneNum: "",
      };
      final.push(obj1);
    } else {
      let obj = {
        examples: defaultAgent?.conversationData,
        agent_name: defaultAgent?.agentName,
        organization_name: defaultAgent?.organisationName,
        greeting_message: defaultAgent.greetingMessage,
        context: defaultAgent?.context,
        rules: defaultAgent?.rules,
        voice_mail_message: defaultAgent?.voicemailMessage,
      };
      let fields = {};
      let manualKeys: any = await dispatch(checkPromptAction(obj));
      if (manualKeys) {
        manualKeys?.fields.map((keys) => {
          fields[keys] = "";
        });
      }
      let obj1 = {
        FirstName: "",
        LastName: "",
        PhoneNum: "",
        ...fields,
      };
      final.push(obj1);
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(final);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Campaign List file` + fileExtension);
    setdownloadexcelSpin(false);
  };
  return (
    <Box
      maxHeight={"450px"}
      sx={{
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Tabs
            value={formState?.source.value}
            onChange={(_, newValue) => {
              if (isEmpty(state?.editedAudience)) {
                setFormState({
                  ...formState,
                  source: { value: newValue },
                });
              }
            }}
            sx={styles.tabs}
          >
            <Tab label="Upload file" value="excel" sx={styles.tab} />
            <Tab label="Create Manually" value="manual" sx={styles.tab} />
          </Tabs>
        </Box>
        <IconButton
          sx={{
            padding: 0,
            gap: 1,
            color: "#4280FF",
            textTransform: "capitalize",
          }}
          disableRipple
          disableFocusRipple
          style={{ outline: "none" }}
          onClick={() => downloadSampleExcel()}
        >
          <DownloadIcon className="download-icon" />
          <Box
            component="span"
            fontSize={14}
            fontWeight={400}
            color="inherit"
            minWidth={180}
          >
            {downloadexcelSpin ? <LoadingDots /> : "Download Sample Excel file"}
          </Box>
        </IconButton>
      </Box>

      {formState?.source.value === "manual" && (
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          {audienceData.map((audience, index) => {
            inde = index;
            return (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                gap={2}
              >
                <Box flex={1}>
                  <Typography {...styles.label}>First Name</Typography>
                  <TextField
                    name="firstName"
                    value={audience.firstName}
                    onChange={(e) => onInputChange(e, index)}
                    variant="outlined"
                    fullWidth
                    placeholder="Enter first name eg: “Olivia”"
                    sx={styles.textInput}
                  />
                </Box>
                <Box flex={1}>
                  <Typography {...styles.label}>Last Name</Typography>
                  <TextField
                    name="lastName"
                    value={audience.lastName}
                    onChange={(e) => onInputChange(e, index)}
                    variant="outlined"
                    fullWidth
                    placeholder="Enter last name eg: “Angel”"
                    sx={styles.textInput}
                  />
                </Box>

                <Box flex={1}>
                  <Typography {...styles.label}>Phone Number</Typography>
                  <Box sx={{ position: "relative" }}>
                    <PhoneInput
                      specialLabel={""}
                      country={"us"}
                      value={audience.phone}
                      enableLongNumbers={true}
                      onChange={(e) => handlePhoneChange(e, index)}
                      buttonStyle={{
                        background: "#f4f4f4",
                        border: "1px solid #E6EBF7",
                        backgroundColor: "#F5F7FB",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                      inputStyle={{
                        padding: "20px 10px 20px 55px",
                        border: "1px solid #CCD2DE",
                        width: "53%",
                      }}
                      containerStyle={{
                        borderRadius: "8px",
                      }}
                      inputProps={{
                        placeholder: "Enter the phone number",
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton onClick={() => removeAudienceForm(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          })}

          {msg && (
            <span className="m-1" style={{ color: "red", fontSize: "13px" }}>
              {msg}
              <br />
              <br />
            </span>
          )}
          <Box
            sx={{
              justifyItems: "flex-start",
            }}
          >
            <Button
              onClick={() => addAudienceForm()}
              variant="outlined"
              startIcon={
                <AddIcon
                  style={{
                    color: "#666666",
                    padding: 1,
                    backgroundColor: "white",
                  }}
                />
              }
              sx={styles.addBtn}
            >
              Add
            </Button>
          </Box>
        </Box>
      )}

      {formState?.source.value === "excel" && (
        <Box>
          <NoticeAlert
            title="Check your file before upload"
            content="It's essential to ensure that the file you're working with is
                valid. After making any adjustments, it's recommended to
                re-verify the data against the existing information to maintain
                accuracy and completeness. Thank you for your attention to these
                details."
          />

          <Box display="flex" alignItems="center" gap={1} my={1}>
            <Box
              component="span"
              sx={{ color: "#1A1D23", fontSize: 14, fontWeight: 500 }}
            >
              Upload Documents
            </Box>
            <InfoIcon />
            <Box
              component="span"
              sx={{ color: "#7E8AAE", fontSize: 13, fontWeight: 400 }}
            >
              Only .exe files are accepted
            </Box>
          </Box>
          <FileUploadComponent  setState={setState}/>
        </Box>
      )}
    </Box>
  );
};

export default TabsSection;

const styles = {
  tabs: {
    marginTop: 2,
    backgroundColor: "#F1F4FA",
    width: "fit-content",
    borderRadius: "6px",
    minHeight: 36,
    ".MuiTabs-indicator": {
      display: "none",
    },
    ".MuiTabs-scroller": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginX: 0.5,
    },
  },
  tab: {
    fontSize: 14,
    fontWeight: 400,
    textTransform: "none",
    padding: "2px 8px",
    color: "#757B93",
    borderRadius: "8px",
    minHeight: 30,
    "&.Mui-selected": {
      backgroundColor: "#fff",
      color: "#333333",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
  },
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 1,
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      color: "#666666",
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "-webkit-text-fill-color": "#000 !important",
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
  addBtn: {
    textTransform: "none",
    color: "#656E7F",
    border: 0,
    backgroundColor: "#F1F4FA",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 2,
    padding: "4px 10px",
    "&:hover": {
      transition: "width 0.5s ease-in-out",
      border: 0,
    },
  },
};
