import React from "react";
import "../../CommonComponents/Modal/CommonModal.css";
import { Modal } from "react-bootstrap";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as BinIcon } from "../../../../assets/Images/Bin.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/Images/CrossIcon.svg";
import { buttonStyles } from "./styles";
import { LoadingSlides } from "../Common";

const DeletePopup = ({
  message,
  show,
  toggle,
  handleDelete,
  spin = false,
  heading = "Are you sure you want to delete?",
  deleteText = "Delete",
  additionalHtml = <></>,
}) => {
  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      contentClassName=".common-modal-450"
      centered={true}
    >
      <Modal.Header
        className="new-modal-header"
        style={{ backgroundColor: "#F1F4FA", border: "none" }}
      >
        <span style={{ fontSize: 14, fontWeight: 600, color: "#181818" }}>
          Delete
        </span>
        <span onClick={toggle} className="modal-cross-icon">
          <CrossIcon />
        </span>
      </Modal.Header>
      <Modal.Body className="new-modal-body">
        <Box className="flex-column" sx={{ justifyContent: "center" }}>
          <Box className="flex-xy-center">
            <Box width={"260px"}>
              <BinIcon className="ml-4" />
              <Typography
                mt={2}
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                color={"#181818"}
              >
                {heading}
              </Typography>
            </Box>
          </Box>
          <Typography
            marginTop={1}
            marginBottom={1}
            textAlign={"center"}
            fontSize={14}
            fontWeight={400}
            color={"#555555"}
          >
            {message}
          </Typography>
        </Box>
        <Box>{additionalHtml}</Box>
        <Box
          marginTop={3}
          px={1.5}
          marginBottom={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button sx={buttonStyles.cancel} onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={spin}
            className="flex-xy-center"
            sx={buttonStyles.delete}
            onClick={handleDelete}
          >
            {spin ? <LoadingSlides bgColor="white" /> : `${deleteText}`}
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default DeletePopup;
