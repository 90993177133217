import * as React from "react";
import { useEffect, useState } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import { axiosClient } from "../../../../Constant/apiConstant";
import { lastLoginTrackAction } from "../../Actions/CommonActions";
import { checkRolesAccess } from "../../CommonComponents/Common";
import { saveSelectedAccount } from "../../Store/Account/Account.action";
import { customStyles } from "./styles";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const drawerWidth = 265;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
  boxShadow: "none",
}));

const settings = [
  { id: "access", name: "Access Manager", to: "/account/access" },
  {
    id: "voice-cloning",
    name: "Voice Cloning",
    to: "/account/voice-cloning",
  },
  { id: "history", name: "Account History", to: "/account/history" },
  { id: "integration", name: "Integration", to: "/account/integrations" },
];
const admin = [
  {
    id: "admin",
    name: "Admin Dashboard",
    to: "/account/admin",
    subRoutes: { to: "/account/recharge" },
  },
  {
    id: "topup",
    name: "Topup Account (Sales)",
    to: "/account/account-topup",
  },
];

const Topbar = ({ open, styles, showSettings, theme, showAdminControls }) => {
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: {
      userDetails: { accounts, _id, role },
      selectedAccount,
    },
    ProjectReducer: { projectList },
  } = reducer;

  const changeAccount = async (account) => {
    let lastLoginObj = {
      accountUrl: account?.accountUrl,
      userId: _id,
      accountId: account?._id,
      track: true,
    };
    let res1: any = await dispatch(lastLoginTrackAction(lastLoginObj));
    await dispatch(saveSelectedAccount(account));
    if (res1) {
      let goTo = checkRolesAccess(res1);
      if (goTo === "dashboard") {
        history.push("/account/dashboard");
      }
      if (goTo === "project") {
        history.push("/account/projects");
      }
    }
    localStorage.removeItem("visitedItems");
  };
  const [state, setState] = useState({
    projectName: "",
  });
  const [activeSetting, setActiveSetting] = useState("access");
  const [activeAdmin, setActiveAdmin] = useState("admin");
  const history = useHistory();
  const { pathname } = useLocation();
  const projectId = pathname.split("/")[3];

  useEffect(() => {
    if (projectList?.length > 0) {
      let fn = projectList?.find((o) => o?._id === projectId)?.projectName;
      setState((prev) => ({
        ...prev,
        projectName: fn,
      }));
    }
  }, [projectList, projectId]);

  useEffect(() => {
    const getCurrentIndex: any = settings.find(
      (item) => item?.to === pathname
    )?.id;
    setActiveSetting(getCurrentIndex);
  }, [pathname]);

  useEffect(() => {
    const getCurrentIndex: any = admin.find(
      (item) => item?.to === pathname || item?.subRoutes?.to === pathname
    )?.id;
    if (getCurrentIndex) {
      setActiveAdmin(getCurrentIndex);
    }
  }, [pathname]);

  const handleClick = (id) => {
    setActiveSetting(id);
    setActiveAdmin(id);
  };

  const adminItems = (role) => {
    return admin.filter((item) => {
      if ((role === "sales" || role === "super_admin") && item.id === "topup") {
        return true;
      }
      if ((role === "admin" || role === "super_admin") && item.id === "admin") {
        return true;
      }
      return false;
    });
  };

  const changeProject = async (project) => {
    localStorage.removeItem("starred");
    localStorage.removeItem("campaignSearchText");
    localStorage.removeItem("campaignpageIndex");
    localStorage.removeItem("campaignRunpageIndex");
    localStorage.removeItem("campaignRunDetailsIndex");
    localStorage.removeItem("searchTextAud");
    localStorage.removeItem("form-data");
    localStorage.removeItem("visitedItems");
    axiosClient.defaults.headers.common["projectId"] = project?._id;
    localStorage.setItem("projectId", project._id);
    history.push(`/account/project/${project?._id}/agents`);
    // if (project?.botPlatform?.includes("llm")) {
    //   history.push(`/account/project/${project?._id}/agents`);
    // } else {
    //   history.push(`/account/project/${project?._id}/campaigns`);
    // }
  };

  return (
    <>
      <AppBar
        sx={{ ...styles.bg, "&.MuiPaper-root": { background: "#181818" } }}
        position="fixed"
        open={open}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "150px",
            }}
          >
            <Box sx={{ ...styles.settingsBox }}>
              {showSettings &&
                settings.map((item) => (
                  <Box
                    key={item?.id}
                    style={{
                      ...styles.settingsNav,
                      backgroundColor: `${
                        activeSetting === item?.id ? "#111111" : "transparent"
                      }`,
                    }}
                    onClick={() => {
                      history.push(item?.to);
                      handleClick(item?.id);
                    }}
                  >
                    <p
                      style={{
                        margin: 1,
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: "13px",
                        color: `${
                          activeSetting === item?.id ? "#FFFFFF" : "#9E9FA1"
                        }`,
                      }}
                    >
                      {item.name}
                    </p>
                    {activeSetting === item?.id && (
                      <Box
                        sx={{
                          height: "5px",
                          width: "100%",
                          background: `${
                            activeSetting === item?.id
                              ? "#FF5E00"
                              : "transparent"
                          }`,
                          borderTopLeftRadius: "6px",
                          borderTopRightRadius: "6px",
                        }}
                      ></Box>
                    )}
                  </Box>
                ))}
            </Box>
            <Box sx={{ ...styles.settingsBox }}>
              {showAdminControls &&
                adminItems(role).map((item) => (
                  <Box
                    key={item?.id}
                    style={{
                      ...styles.settingsNav,
                      width: "150px",
                      backgroundColor: `${
                        activeAdmin === item?.id ? "#111111" : "transparent"
                      }`,
                    }}
                    onClick={() => {
                      history.push(item?.to);
                      handleClick(item?.id);
                    }}
                  >
                    <p
                      style={{
                        margin: 1,
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: "13px",
                        color: `${
                          activeAdmin === item?.id ? "#FFFFFF" : "#9E9FA1"
                        }`,
                      }}
                    >
                      {item.name}
                    </p>
                    {activeAdmin === item?.id && (
                      <Box
                        sx={{
                          height: "5px",
                          width: "100%",
                          background: `${
                            activeAdmin === item?.id ? "#FF5E00" : "transparent"
                          }`,
                          borderTopLeftRadius: "6px",
                          borderTopRightRadius: "6px",
                        }}
                      ></Box>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!showSettings && (
              <Typography fontWeight={600} fontSize="13px" color="#9E9FA1">
                {projectId ? "Current Project" : "Current Account"}
              </Typography>
            )}
            {projectId ? (
              <Select
                styles={customStyles}
                maxMenuHeight={180}
                className="basic-single project-select"
                classNamePrefix="select"
                value={projectList?.find(
                  (project) => project?._id === projectId
                )}
                isDisabled={false}
                isSearchable={true}
                options={projectList}
                getOptionLabel={(e: any) => e?.projectName}
                getOptionValue={(e: any) => e?._id}
                onChange={(e) => {
                  changeProject(e);
                }}
                placeholder="Select Project"
                components={{
                  Input: (props) => (
                    <components.Input {...props} maxLength={30} />
                  ),
                }}
              />
            ) : (
              <Select
                styles={customStyles}
                maxMenuHeight={180}
                className="basic-single  project-select"
                classNamePrefix="select"
                value={accounts?.find(
                  (project) =>
                    project.accountName === selectedAccount?.accountName
                )}
                isDisabled={false}
                isSearchable={true}
                options={accounts}
                getOptionLabel={(e: any) => e?.accountName}
                getOptionValue={(e: any) => e?._id}
                onChange={(e) => {
                  changeAccount(e);
                }}
                placeholder="Select Account"
                components={{
                  Input: (props) => (
                    <components.Input {...props} maxLength={30} />
                  ),
                }}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
